
import { Component, Vue, Prop } from 'vue-property-decorator';
import { VsOption } from '../vs-alert';
@Component({ })
class VsSelectModal extends Vue {

    @Prop({ type: Boolean, required: true })
    private readonly isOpened!: boolean;

    @Prop({ type: Array, required: true })
    private readonly options!: VsOption[];

}

export default VsSelectModal;
