
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ })
class VsAside extends Vue {

    @Prop({ type: String, default: 'Titulo' })
    private title!: string;
    
    @Prop({ type: String, default: 'Subtitulo' })
    private subtitle!: string;
    
    @Prop({ type: String, default: 'calendar' })
    private icon!: string;
}

export default VsAside;
